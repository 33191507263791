import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(main)": [~37,[4],[5]],
		"/(main)/blog": [~38,[4],[5]],
		"/(main)/blog/[post]/[[title]]": [~39,[4],[5]],
		"/(main)/category/[id]/[[name]]": [~40,[4],[5]],
		"/(dashboard)/dashboard": [6,[2,3]],
		"/(dashboard)/dashboard/admin": [~7,[2,3]],
		"/(dashboard)/dashboard/admin/clear-accounts": [~8,[2,3]],
		"/(dashboard)/dashboard/admin/emails": [9,[2,3]],
		"/(dashboard)/dashboard/admin/init-custom-flavors": [~10,[2,3]],
		"/(dashboard)/dashboard/admin/playground": [~11,[2,3]],
		"/(dashboard)/dashboard/admin/reflect": [~12,[2,3]],
		"/(dashboard)/dashboard/admin/refresh-config": [~13,[2,3]],
		"/(dashboard)/dashboard/admin/spreadsheet": [~14,[2,3]],
		"/(dashboard)/dashboard/admin/telegram": [15,[2,3]],
		"/(dashboard)/dashboard/analytics/requested-flavors": [~16,[2,3]],
		"/(dashboard)/dashboard/analytics/search": [~17,[2,3]],
		"/(dashboard)/dashboard/blog": [~18,[2,3]],
		"/(dashboard)/dashboard/blog/post/[postid]": [~19,[2,3]],
		"/(dashboard)/dashboard/inquiries": [~20,[2,3]],
		"/(dashboard)/dashboard/inquiries/[...id]": [~21,[2,3]],
		"/(dashboard)/dashboard/inquiry-stats": [~22,[2,3]],
		"/(dashboard)/dashboard/newsletter": [~23,[2,3]],
		"/(dashboard)/dashboard/not-allowed": [24,[2,3]],
		"/(dashboard)/dashboard/orders": [~25,[2,3]],
		"/(dashboard)/dashboard/orders/[...id]": [~26,[2,3]],
		"/(dashboard)/dashboard/partners": [~27,[2,3]],
		"/(dashboard)/dashboard/prices": [~28,[2,3]],
		"/(dashboard)/dashboard/products": [~29,[2,3]],
		"/(dashboard)/dashboard/products/[...id]": [~30,[2,3]],
		"/(dashboard)/dashboard/resources": [~31,[2,3]],
		"/(dashboard)/dashboard/tenders": [~32,[2,3]],
		"/(dashboard)/dashboard/tenders/[...tenderId]": [~33,[2,3]],
		"/(main)/inquiry": [41,[4],[5]],
		"/(main)/inquiry/confirmation/[...inquiryId]": [~42,[4],[5]],
		"/(main)/legal/privacy-policy": [43,[4],[5]],
		"/(links)/links/invoice/[...id]": [~36],
		"/(main)/login2/[...expected]": [~44,[4],[5]],
		"/(main)/order/confirmation/[...orderId]": [~46,[4],[5]],
		"/(main)/order/[...inquiryId]": [~45,[4],[5]],
		"/(main)/product/[id]/[[name]]": [~47,[4],[5]],
		"/(main)/register/thank-you": [48,[4],[5]],
		"/(main)/search": [49,[4],[5]],
		"/(main)/ui2": [50,[4],[5]],
		"/(dashboard)/ui": [~34,[2]],
		"/(dashboard)/ui/dp": [35,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';