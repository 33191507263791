import type { HandleClientError } from '@sveltejs/kit'
import type { Err } from './lib/types/error'

export const handleError: HandleClientError = ({ error, event }) => {
	const err = error as Err

	console.error({
		error: err,
		url: event.url.toString(),
	})

	return {
		message: 'Whoops!',
		code: err.name ?? 'UNKNOWN',
	}
}
